<template>
  <b-card title="Sales Statistic">
    <b-row>
      <b-col cols="6" md="3">
        <div class="d-flex align-center">
          <div
            class="v-avatar v-theme--light text-primary v-avatar--density-default v-avatar--rounded v-avatar--variant-tonal"
            style="width: 40px; height: 40px"
          >
            <i class="tabler-chart-pie-2 v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true"></i
            ><span class="v-avatar__underlay"></span>
          </div>
          <div class="d-flex flex-column">
            <h5 class="text-h5">230k</h5>
            <div class="text-sm">Sales</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: `SalesStatistic`,
  data() {
    return {
      payload: {
        sales: 0,
        profit: 0,
        totalOrder: 0,
        totalProduct: 0,
      },
      loader: false,
    };
  },
  async mounted() {},
  methods: {},
};
</script>
