<template>
  <div>
    <sales-statistic />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SalesStatistic from "./SalesStatistic.vue";

export default {
  name: `MainDashboard`,
  components: { SalesStatistic },
  computed: {
    ...mapState(["user"]),
  },
  async mounted() {},
  methods: {},
};
</script>
